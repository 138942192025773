import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import produce from "immer";
import React, { forwardRef, useEffect, useState } from "react";

type Item = { id: string; content: React.ReactNode };
interface SortableGridProps {
  data: Item[];
  gridColumns?: number;
  onSortEnd?: (newOrder: number[]) => void;
}
interface GridProps {
  children: React.ReactNode;
  columns: number;
}
interface SortableItemProps {
  id: string;
  index: number;
  children?: React.ReactNode;
}
interface ItemProps {
  index: number;
  faded?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const SortableGrid = ({
  data,
  gridColumns = 6,
  onSortEnd,
}: SortableGridProps) => {
  const [items, setItems] = useState(data);
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  return (
    <Grid columns={gridColumns}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items}>
          {items.map(({ id, content }, index) => (
            <SortableItem key={id} id={id} index={index}>
              {content}
            </SortableItem>
          ))}
        </SortableContext>

        <DragOverlay adjustScale={true}>
          {activeId ? (
            <Item index={items.findIndex((item) => item.id === activeId)} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </Grid>
  );

  function handleDragStart(event: DragStartEvent) {
    const active = event.active.id;
    setActiveId(active as string);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const newOrder = produce(items, (draft) => {
        const oldIndex = draft.findIndex((item) => item.id === active.id);
        const newIndex = draft.findIndex((item) => item.id === over?.id);

        draft.splice(newIndex, 0, draft.splice(oldIndex, 1)[0]);

        return draft;
      });

      setItems(newOrder);

      if (onSortEnd)
        onSortEnd(
          newOrder
            .map((item) => parseInt(item.id))
            ?.filter((item) => !isNaN(item)),
        );
    }

    setActiveId(null);
  }
};

export function Grid({ children, columns }: GridProps) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        rowGap: 1,
        columnGap: 1,
      }}
    >
      {children}
    </Box>
  );
}

export const SortableItem = (props: SortableItemProps) => {
  const { id, children } = props;
  const sortable = useSortable({ id });
  const { listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item ref={setNodeRef} style={style} {...props} {...listeners}>
      {children}
    </Item>
  );
};

export const Item = forwardRef(
  (
    { index, faded, style, ...props }: ItemProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const inlineStyles = {
      opacity: faded ? "0.2" : "1",
      transformOrigin: "0 0",
      height: index === 0 ? 334 : 106,
      gridRowStart: index === 0 ? "span 3" : null,
      gridColumnStart: index === 0 ? "span 3" : null,
      border: "1px solid #ebebf0",
      borderRadius: "6px",
      ...style,
    };

    return (
      <Box ref={ref} sx={inlineStyles} {...props}>
        {props.children}
      </Box>
    );
  },
);
Item.displayName = "Item";
